import $ from 'jquery';

class Accordion {

  constructor() {
    $('.accordion-header').on('click', (e) => {
      e.stopPropagation();
      var $target = $(e.currentTarget),
          $detail = $target.next('.accordion-detail'),
          close = $detail.hasClass('active');
      $('.accordion-detail').removeClass('active');
      if (close) return;
      $detail.addClass('active').prev('.accordion-header')[0].scrollIntoView();
    });
  }

}

export default Accordion;
