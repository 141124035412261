// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

import DateUtil from './src/date-util'
import Schedules from './src/schedules'

window.du = new DateUtil();

import ModalPlayer from './src/modal-player'
import ScheduleElements from './src/schedule-elements'
import Selector from './src/selector'
import ChannelFinder from './src/channel-finder'
import Accordion from './src/accordion'
import RemoteForms from './src/remote-forms'
import Readings from './src/readings'
import WeeklyGrid from './src/weekly-grid'
import Modal from './src/modal'
import BannerSlot from './src/banner-slot'
import Visibility from './src/visibility'
import OurBooks from './src/our-books'

window.audioPlayerAccessibility = function($container) {
  if ($container.length < 1) return;
  let player = $container.find('audio')[0];
  player.onplay = () => { $('#pause-button').show(); $('#play-button').hide(); }
  player.onpause = () => { $('#pause-button').hide(); $('#play-button').show(); }
  $container.find('#play-button').click(function(e) { e.stopPropagation(); player.play(); });
  $container.find('#pause-button').click(function(e) { e.stopPropagation(); player.pause(); }).hide();
}

// very basic template renderer - variable names must be enclosed in {}
// note: for simplicity, {} cannot appear in the rendered output (no escaping possible)
window.replaceAll = function(s, o) {
  var strings = s.split(/[{}]/), out = [];
  for (var i = 0; i < strings.length; i++) {
    out.push(i % 2 == 0 ? strings[i] : o[strings[i]]);
  }
  return out.join('');
}

document.addEventListener('turbo:load', function() {
  window.ewtn = { lang: $('html').attr('lang'), foo: 'bar', ack: 'pht' };
  dataLayer.push({ event: 'pageView', virtualUrl: window.location.href }); // so GTM tags can fire on link navigation

  new BannerSlot();
  new ModalPlayer();
  new ScheduleElements();
  new Selector();
  new ChannelFinder();
  new Accordion();
  new RemoteForms();
  new Readings();
  new WeeklyGrid();
  new Schedules();
  new Modal();
  new Visibility();
  new OurBooks();

  $('.hamburger').on('click', function(e) {
    e.stopPropagation();
    $(this).toggleClass('hamburger__change');
    if ($(this).hasClass('hamburger__change'))
      $('.mega-menu').slideDown();
    else
      $('.mega-menu').slideUp();
  });
  audioPlayerAccessibility($('#audio-player-container'));

  // undocumented fix for ShareThis turbolinks incompatibility: https://github.com/reed/turbolinks-compatibility/issues/79
  if (typeof __sharethis__ != 'undefined' && __sharethis__.config) {
    __sharethis__.href = document.location.href;
    __sharethis__.init(__sharethis__.config);
  }

  let $hs = $('[data-hubspot-form-id]');
  if ($hs.length > 0) {
    $hs.find('iframe').remove();
    hbspt.forms.create({
      portalId: "4395239",
      formId: $hs.data('hubspot-form-id'),
      target: '#hs-form'
    });
  }
  // Marin - RaiseDonors tracking
  let $div = $('#rd-marin-tracker');
  if (new URLSearchParams(window.location.search).has('mkwid') && $('#raiseDonorsEmbedForm').length < 1) {
    $div.html($div.find('script').html().replace('{params}', window.location.search.substring(1)));
  } else {
    $div.html('');
  }
});

// turbolinks fix for Google reCAPTCHA (https://github.com/turbolinks/turbolinks/issues/253#issuecomment-289101048)
document.addEventListener('turbo:before-cache', () => {
  $('.g-recaptcha').empty();
});
