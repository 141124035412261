import $ from 'jquery'
import Fader from './fader'

class BannerSlot {

  constructor() {
    this.$slots = $('[data-banner-slot]');
    if (this.$slots.length < 1) return;
    let location = window.location.pathname.replace(/\//g, '!');
    if (location.length > 1 && '!' == location[location.length - 1]) location = location.substring(0, location.length - 1);
    $.ajax({
      url: '/api/banner-slots/' + location,
      dataType: 'json',
      success: (data) => this.handleSlot(data)
    });
    this.destructor = this.destructor.bind(this);
    document.addEventListener('turbo:before-cache', this.destructor);
  }

  handleSlot(data) {
    this.$slots.each((i, slot) => {
      let $slot = $(slot),
          banners = data[$slot.data('banner-slot')];
      if ($slot.hasClass('_inserter'))
        this.buildInserter(banners, $slot);
      else
        this.buildSlot(banners, $slot);
    });
  }

  // Pick n distinct numbers between 0 and m - 1; based on Fisher-Yates shuffle
  pickNFromM(n, m) {
    let candidates = Array.from(Array(m).keys()), i = m, target, tmp;
    while (i >= m - n && i > 0) {
      target = Math.floor(Math.random() * i--),
      tmp = candidates[i];
      candidates[i] = candidates[target];
      candidates[target] = tmp;
    }
    return candidates.slice(m - n, m);
  }

  shuffle(list) {
    for (let i = list.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
  }

  selectBanners(data) {
    let banners = [], overrides = {}, candidates = [], selected = {}
    for (let i = 0; i < data.banners.length; i++) {
      let banner = data.banners[i];
      if (banner.override) {
        overrides[banner.override - 1] = banner;
      } else {
        for (let j = 0; j < banner.weight; j++) {
          candidates.push(banner);
        }
      }
    }
    this.shuffle(candidates);
    let j = 0;
    for (let i = 0; i < data.count; i++) {
      if (overrides[i]) {
        banners.push(overrides[i]);
      } else {
        while (j < candidates.length && selected[candidates[j].id]) j++;
        if (j >= candidates.length) continue;
        banners.push(candidates[j]);
        selected[candidates[j].id] = true;
      }
    }
    return banners;
  }

  buildSlot(data, $slot) {
    if (data.banners.length == 0) {
      $slot.remove();
      return;
    }
    let $bannerTemplate = $slot.find('script'),
        banners = this.selectBanners(data);
    for (let i = 0; i < banners.length; i++) {
      let $banner = $(replaceAll($bannerTemplate.html(), banners[i]));
      if (0 == i) $banner.addClass('active');
      if ('' == banners[i].link_url) $banner.find('a').attr('href', null);
      $slot.append($banner);
    }
    if ($slot.hasClass('_fader')) {
      let $dotContainer = $('#' + $slot.data('dot-container')),
          $dotTemplate = $dotContainer.find('script');
      for (let i = 0; i < banners.length; i++) {
        let $dot = $(replaceAll($dotTemplate.html(), { dot_index: i }));
        if (0 == i) $dot.find('._fader-dot').addClass('active');
        $dotContainer.append($dot);
      }
      this.fader = new Fader($slot, $dotContainer, $slot.data('fader-duration') || 7000);
    }
  }

  buildInserter(data, $inserter) {
    let $items = $inserter.find('._inserter-item'),
        $lastItem = $items.eq($items.length - 1);
    $lastItem.show(); // in case no banner to insert and cached version is hidden
    if (data.banners.length == 0) return;
    let $bannerTemplate = $inserter.find('script'),
        banners = this.selectBanners(data),
        $banner = $(replaceAll($bannerTemplate.html(), banners[0]));
    $items.eq(Math.floor(Math.random() * $items.length)).before($banner);
    $lastItem.hide();
  }

  destructor() {
    if (typeof this.fader !== 'undefined') {
      this.fader.stop();
      this.fader.$dotContainer.find('._banner-item').remove();
    }
    this.$slots.find('._banner-item').remove();
  }
}

export default BannerSlot;
